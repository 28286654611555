<template>
  <div
    v-if="workout"
    class="workoutProgressModal"
  >
    <div class="workoutProgressModal__heading">
      <p class="workoutProgressModal__headingTitle">
        {{ $t('workoutProgressModal.title') }}
      </p>

      <div class="workoutProgressModal__headingDate">
        {{ date }}
      </div>

      <p class="workoutProgressModal__paragraphProgram">
        {{ workout.program }}
      </p>

      <div class="workoutProgressModal__info">
        <span>
          <font-awesome-icon icon="person-running" />
          {{ workout.workout }}
        </span>

        <span>
          <font-awesome-icon icon="grid" />
          {{ workout.period }}
        </span>
      </div>
    </div>

    <div class="workoutProgressModal__rating">
      <p class="workoutProgressModal__ratingParagraph">
        {{ $t('workoutProgressModal.ratingLabel') }}
      </p>

      <rating-icon v-bind:rating="workout.feedback" />

      <p class="workoutProgressModal__ratingWord">
        {{ ratingWord }}
      </p>
    </div>

    <p class="workoutProgressModal__note">
      <strong>
        {{ $t('workoutProgressModal.noteLabel') }}
      </strong>
      <br />

      {{ workout.note ? workout.note : $t('workoutProgressModal.noNoteLabel') }}
    </p>

    <base-button
      modifiers="secondary block fullWidth"
      v-on:click="$emit('close')"
    >
      {{ $t('workoutProgressModal.closeButtonLabel') }}
    </base-button>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton';
import RatingIcon from '@/components/RatingIcon';
import workoutRatings from '@/data/workoutRatings';

export default {
  components: {
    BaseButton,
    RatingIcon,
  },

  props: {
    workout: {
      type: Object,
      required: true,
    },
  },

  computed: {
    date() {
      const date = new Date(this.workout.finished_at);

      return date.toLocaleString('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    },

    ratingWord() {
      const rating = parseInt(this.workout.feedback);

      if (!rating) {
        return this.$t('workoutProgressModal.notRatedLabel');
      }

      return workoutRatings.find(({ value }) => value === rating).name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.workoutProgressModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.workoutProgressModal__headingTitle {
  margin: rem(32px) 0 0 0;
  color: $color-blue;
}

.workoutProgressModal__paragraphProgram {
  margin: 0 0 rem(24px) 0;
}

.workoutProgressModal__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workoutProgressModal__headingDate {
  @include heading-2;
}

.workoutProgressModal__info {
  color: $color-text;

  span:first-child {
    margin: 0 32px 0 0;
  }

  .svg-inline--fa {
    margin: 0 8px 0 0;
    color: $color-yellow;
  }
}

.workoutProgressModal__rating {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.workoutProgressModal__ratingWord {
  @include label;
  margin: rem(8px) 0;
  color: $color-blue;
}

.workoutProgressModal__ratingParagraph {
  @include paragraph--bold;
  margin: 0 0 rem(8px) 0;
}

.workoutProgressModal__note {
  margin: 0 0 rem(20px) 0;
  text-align: center;
}
</style>
