import { i18n } from '@/plugins/i18n';

export default [
  {
    name: i18n.global.t('workoutRatings.rating1'),
    value: 1,
  },
  {
    name: i18n.global.t('workoutRatings.rating2'),
    value: 2,
  },
  {
    name: i18n.global.t('workoutRatings.rating3'),
    value: 3,
  },
  {
    name: i18n.global.t('workoutRatings.rating4'),
    value: 4,
  },
  {
    name: i18n.global.t('workoutRatings.rating5'),
    value: 5,
  },
  {
    name: i18n.global.t('workoutRatings.rating1'),
    value: 6,
  },
  {
    name: i18n.global.t('workoutRatings.rating2'),
    value: 7,
  },
  {
    name: i18n.global.t('workoutRatings.rating3'),
    value: 8,
  },
  {
    name: i18n.global.t('workoutRatings.rating4'),
    value: 9,
  },
  {
    name: i18n.global.t('workoutRatings.rating5'),
    value: 10,
  },
];